/**
 * @generated SignedSource<<63b0091485f5a43066b214f456af2c2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BonusBannerImage_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BonusFallbackImage_offer">;
  readonly " $fragmentType": "BonusBannerImage_offer";
};
export type BonusBannerImage_offer$key = {
  readonly " $data"?: BonusBannerImage_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BonusBannerImage_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BonusBannerImage_offer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusFallbackImage_offer"
    }
  ],
  "type": "IBonusOffer",
  "abstractKey": "__isIBonusOffer"
};

(node as any).hash = "8a629ecfebaa535c9f2af6af0fbd3c58";

export default node;
