import styled from '@emotion/styled';
import { Button } from '@pafcloud/base-components';
import { Color } from '@pafcloud/style';

export const CtaButton = styled(Button)({
  width: '50%',
  maxWidth: 200,

  '--button-background': Color.Primitive.Secondary,
  '--button-border': 'transparent',
  '--button-background--hover': Color.Primitive.SecondaryTint,
  '--button-background--active': Color.Primitive.SecondaryTint,
  '--button-focus-ring': Color.Primitive.Secondary,
});
